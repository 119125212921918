import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataContext } from "../../contexts/DataContext";

const SheetFormTab3 = ({ setNextTab, showOrEdit }) => {
  let contextData = useContext(DataContext);
  let itemContext = contextData.clinicalSheet.pregnancyCase;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pregnant: "no",
      prenatalConntrol: "no",
      startdate: "",
      normalBirths: 0,
      caesareanBirths: 0,
      abortions: 0,
      lactation: "no",
      lactationTime: 0,
    },
    validationSchema: Yup.object().shape({
      //Aqui tengo que poner la validacion con yup
    }),
    onSubmit: async () => {
      setNextTab("4");
    },
  });
  useEffect(() => {
    const showItems = () => {
      formik.setValues({
        pregnant: itemContext.pregnant,
        prenatalConntrol: itemContext.prenatalConntrol,
        startdate: itemContext.startdate,
        normalBirths: itemContext.normalBirths,
        caesareanBirths: itemContext.caesareanBirths,
        abortions: itemContext.abortions,
        lactation: itemContext.lactation,
        lactationTime: itemContext.lactationTime,
      });
    };
    showItems();
    setNextTab("3");
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextTab]);
  return (
    <div
      id="div-scrolling-style-result"
      style={{
        overflowY: "auto",
        height: "74vh",
        paddingBottom: "100px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid id="grid-style-11" item={true} md={12}>
            <div id="div-style-9">
              <Typography color={"#fff"} variant="subtitle1" gutterBottom>
                *Contestar solo si esta embarazada
              </Typography>
            </div>
          </Grid>
          <Grid item={true} xs={12} sx={{ marginTop: "10px" }}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="subtitle2">
                    ¿Está embarazada en estos momentos?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="pregnant"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.pregnant}
                  onChange={(e) => {
                    formik.setFieldValue("pregnant", e.target.value);
                    itemContext.pregnant = e.target.value;
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no sabe"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No lo se</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid id="grid-style-10" item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Control prenatal
            </Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="subtitle2">
                    ¿Realizas control prenatal?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="prenatalConntrol"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.prenatalConntrol}
                  onChange={(e) => {
                    formik.setFieldValue("prenatalConntrol", e.target.value);
                    itemContext.prenatalConntrol = e.target.value;
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    disabled={showOrEdit}
                    value="si"
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    disabled={showOrEdit}
                    value="no"
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={4} sx={{ marginTop: "5px" }}>
            <InputLabel shrink>Fecha de inicio</InputLabel>
            <TextField
              id="startDate"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("startDate", e.target.value);
                itemContext.startDate = e.target.value;
              }}
              value={formik.values.startDate}
              onBlur={formik.handleBlur}
              type="date"
              size="small"
            />
          </Grid>
          <Grid id="grid-style-10" item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Indique número de partos
            </Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Normales</InputLabel>
            <TextField
              id="normalBirths"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("normalBirths", e.target.value);
                itemContext.normalBirths = e.target.value;
              }}
              value={formik.values.normalBirths}
              onBlur={formik.handleBlur}
              type="number"
              size="small"
            />
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Cesáreas</InputLabel>
            <TextField
              id="caesareanBirths"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("caesareanBirths", e.target.value);
                itemContext.caesareanBirths = e.target.value;
              }}
              value={formik.values.caesareanBirths}
              onBlur={formik.handleBlur}
              type="number"
              size="small"
            />
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Abortos</InputLabel>
            <TextField
              id="abortions"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("abortions", e.target.value);
                itemContext.abortions = e.target.value;
              }}
              value={formik.values.abortions}
              onBlur={formik.handleBlur}
              type="number"
              size="small"
            />
          </Grid>
          <Grid id="grid-style-10" item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Lactancia
            </Typography>
          </Grid>

          <Grid item={true} xs={4}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="subtitle2">
                    ¿Realizo lactancia materna?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="lactation"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.lactation}
                  onChange={(e) => {
                    formik.setFieldValue("lactation", e.target.value);
                    itemContext.lactation = e.target.value;

                    if (e.target.value === "si") {
                      formik.setFieldValue("lactationTime", 6);
                      itemContext.lactationTime = 6;
                    } else {
                      formik.setFieldValue("lactationTime", 0);
                      itemContext.lactationTime = 0;
                    }
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={8}>
            <InputLabel shrink>Tiempo máximo amamantando (en meses)</InputLabel>
            <TextField
              id="lactationTime"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("lactationTime", e.target.value);
                itemContext.lactationTime = e.target.value;
              }}
              value={formik.values.lactationTime}
              onBlur={formik.handleBlur}
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 6 } }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ marginTop: "30px", paddingLeft: "24px", paddingRight: "24px" }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              paddingRight: "10px",
            }}
          >
            <Button
              id="button-none-transform-color-white"
              variant="contained"
              fullWidth
              onClick={() => {
                setNextTab("2");
              }}
            >
              Atrás
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              paddingLeft: "10px",
            }}
          >
            <Button
              id="button-none-transform-color-white"
              color="secondary"
              type="submit"
              variant="contained"
              disabled={showOrEdit}
              fullWidth
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SheetFormTab3;
