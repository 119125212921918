import {
  Dashboard,
  HealthAndSafety,
  Analytics,
  MedicalInformation,
  EventNote,
  Folder,
} from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const NavbarData = [
  {
    id: 1,
    menu: "Dashboard",
    icon: <Dashboard/>,
    submenu: false,
    link: "/dashboard",
  },
  {
    menu: "Salud",
    icon: <HealthAndSafety/>,
    submenu: true,
    subItem: [
      { id: 2, sublabel: "Recepción", link: "/reception", icon: <EventNote /> },
      {
        id: 3,
        sublabel: "Hoja clinica",
        link: "/sheet",
        icon: <MedicalInformation />,
      },
      { id: 4, sublabel: "Resultados", link: "/results", icon: <MonitorHeartIcon /> },
      { id: 5, sublabel: "Diagnósticos", link: "/diagnoses", icon: <Folder /> },
    ],
  },
  {
    menu: "Analíticas",
    icon: <Analytics/>,
    submenu: true,
    subItem: [],
  },
  {
    menu: "Configuración",
    icon: <TuneIcon/>,
    submenu: true,
    subItem: [
      { id: 6, sublabel: "Usuarios", link: "/users", icon: <PersonIcon /> },
      { id: 7, sublabel: "Permisos", link: "/permissions", icon: <VpnKeyIcon /> },
    ],
  },
];

export default NavbarData;
