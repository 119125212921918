import React from "react";
import {
  Avatar,
  Box,
  List,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import XMi from "../assets/imagesDefault";
import { NavLink } from "react-router-dom";
import NavbarData from "./navbar/NavbarData";
import SideBarItem from "../components/navbar/SideBarItem";
import theme from "../assets/theme";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)})`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - 64px)`, // Tamaño del AppBar cerrado. El spacing es el espacio del Drawer cerrado
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`, // Tamaño del AppBar abierto
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="right" />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.black.one,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.black.one,
  },
}));

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(1);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const onClickReturn = (value) => {
    setIndex(value);
  };

  return (
    <Box sx={{ display: "flex" }} id="backgroundcolor-page">
      <Box
        component="main"
        style={{ width: "100%" }}
        id="backgroundcolor-page-100h-100w"
      >
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "white",
            color: "black",
          }}
          elevation={0}
        >
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div />
            <div
              className="d-flex"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Tooltip title="">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="image" src={XMi.WomanDefaultAvatar} />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            style: {
              border: "none",
              backgroundColor: "transparent",
            },
          }}
        >
          <div
            style={{
              height: "100%",
              backgroundColor: theme.palette.gray.three,
            }}
          >
            <DrawerHeader
              style={{
                alignItems: "center",
                backgroundColor: open ? "transparent" : "white",
              }}
            >
              <div style={{ margin: "auto", display: open ? null : "none" }}>
                <img src={XMi.LogoDefault} width={"70px"} alt="Logo" />
              </div>
              <IconButton
                onClick={handleDrawer}
                style={{ margin: open ? null : "auto" }}
              >
                {open ? (
                  <ChevronLeftIcon style={{ color: "white" }} />
                ) : (
                  <img src={XMi.RibbonXPink} width={"24px"} alt="Logo X" />
                )}
              </IconButton>
            </DrawerHeader>

            <Divider />

            <List style={{ padding: "0px" }}>
              {NavbarData.map((nav, key) => (
                <React.Fragment key={key}>
                  {nav.submenu === false ? (
                    <CustomTooltip title={open ? "" : nav.menu}>
                      <ListItem
                        disablePadding
                        key={key}
                        sx={{
                          display: "block",
                          backgroundColor:
                            index === nav.id
                              ? theme.palette.white.two
                              : "transparent",
                          paddingLeft: "4px",
                        }}
                        onClick={() => {
                          setIndex(nav.id);
                        }}
                      >
                        <ListItemButton
                          component={NavLink}
                          to={nav.link}
                          sx={{
                            minHeight: 48,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 1,
                              color:
                                index === nav.id
                                  ? theme.palette.primary.main
                                  : theme.palette.white.one,
                            }}
                          >
                            {nav.icon}
                          </ListItemIcon>
                          <text
                            style={{
                              display: open ? null : "none",
                              color:
                                index === nav.id
                                  ? theme.palette.primary.main
                                  : theme.palette.white.one,
                            }}
                          >
                            {nav.menu}
                          </text>
                        </ListItemButton>
                      </ListItem>
                    </CustomTooltip>
                  ) : (
                    <SideBarItem
                      item={nav}
                      key={key}
                      drawerStatus={open}
                      indexSelected={index}
                      onClickReturn={onClickReturn}
                    />
                  )}
                </React.Fragment>
              ))}
            </List>

            <Divider />
          </div>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
