import React from "react";
import moment from "moment";
import { Grid, Typography, InputLabel, Avatar } from "@mui/material";

const ClinicalSheet = ({ patientItem }) => {
	const breastHistoryItems = [{ mammogramXDiagnosis: "Mastografía rayos X" }, "Ultrasonido", "Mastografía M3M", "Resonancia magnética", "Otro"];
	const upperCaseFirstLetter = (value) => {
		return value.charAt(0).toUpperCase() + value.slice(1);
	};

	const formatDate = (value) => {
		if (value === "") {
			return "No";
		} else {
			return moment(new Date(value)).format("DD/MM/YYYY");
		}
	};

	const formatEmptyValue = (value) => {
		if (value === "") {
			return "No";
		}
		return value;
	};

	const issueLenguageConvert = (name) => {
		switch (name) {
			case "breastCancer":
				return "Cáncer de pecho";
			case "colonCancer":
				return "Cáncer de colon";
			case "ovarianCancer":
				return "Cáncer de ovario";
			case "hypertension":
				return "Hipertensión";
			case "thyroid":
				return "Tiroides";
			case "diabetes":
				return "Diabetes";
			case "lymphopro":
				return "Linfoproliferativas (Linfona/Leucemia)";
			case "autoimmune":
				return "Enfermedad autoinmune";
		}
	};

	return (
		<div style={{ marginBottom: "50px" }}>
			<Grid id="grid-style-4" md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Datos personales
				</Typography>
			</Grid>
			<div>
				<Grid container spacing={2} style={{ padding: "16px" }}>
					{/* Género */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Género:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.patientData.gender}</InputLabel>
					</Grid>

					{/* Edad */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Edad:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{`${patientItem.patientData.age} años`}</InputLabel>
					</Grid>

					{/* Nacimiento */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Nacimiento:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.patientData.birthday)}</InputLabel>
					</Grid>

					{/* Peso */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Peso:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{`${patientItem.clinicalSheetData.clinicalHistory.weight} kg`}</InputLabel>
					</Grid>

					{/* Altura */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Altura:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.clinicalHistory.height}</InputLabel>
					</Grid>

					{/* IMC */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>IMC:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.clinicalHistory.imc}</InputLabel>
					</Grid>

					{/* Motivo */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Motivo:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.receptionData.reason}</InputLabel>
					</Grid>

					{/* Estudio realizado */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Estudio realizado:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.receptionData.studyType}</InputLabel>
					</Grid>

					{/* Fecha del estudio */}
					<Grid item xs={4}>
						<InputLabel style={{ color: "#4D4D4D" }}>Fecha del estudio:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.receptionData.studyDate)}</InputLabel>
					</Grid>
				</Grid>
			</div>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Antecedentes toxicológicos
				</Typography>
			</Grid>
			<Grid container spacing={2} style={{ padding: "16px" }}>
				<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "#4D4D4D",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						Consumo de alcohol:
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<InputLabel style={{ color: "black", fontWeight: "700" }}>
						{patientItem.clinicalSheetData.clinicalHistory.alcohol === "no" ? "No" : patientItem.clinicalSheetData.clinicalHistory.drinkfrequency}
					</InputLabel>
				</Grid>

				<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "#4D4D4D",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						Consumo de tabaco:
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<InputLabel style={{ color: "black", fontWeight: "700" }}>
						{patientItem.clinicalSheetData.clinicalHistory.tobaco === "no" ? "No" : patientItem.clinicalSheetData.clinicalHistory.smokefrequency}
					</InputLabel>
				</Grid>

				<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "#4D4D4D",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						Comentarios:
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<div style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.clinicalHistory.comments ?? "Sin comentarios"}</div>
				</Grid>
			</Grid>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Enfermedades crónico-degenerativas
				</Typography>
			</Grid>
			<Grid container spacing={2} style={{ padding: "16px" }}>
				{Object.values(patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative).some((value) => value) ? (
					Object.entries(patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative).map(
						([key, value]) =>
							value && (
								<Grid item xs={12} style={{ display: "flex", alignItems: "center" }} key={key}>
									<Avatar src={null} id="avatar-dot-pink"></Avatar>
									<InputLabel
										style={{
											color: "black",
											fontWeight: "700",
											marginLeft: "5px",
										}}
									>
										{issueLenguageConvert(key)}
									</InputLabel>
								</Grid>
							)
					)
				) : (
					<Grid item xs={12}>
						<InputLabel
							style={{
								color: "black",
								fontWeight: "700",
								marginLeft: "5px",
							}}
						>
							Ninguna
						</InputLabel>
					</Grid>
				)}
			</Grid>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Antecedentes oncológicos heredofamiliares
				</Typography>
			</Grid>
			<Grid container spacing={2} style={{ padding: "16px" }}>
				<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "#4D4D4D",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						Cáncer de mama:
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.clinicalHistory.breastCancer.breastCancerMessage}</InputLabel>
				</Grid>
				<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "#4D4D4D",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						Cáncer de ovario:
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.clinicalHistory.ovarianCancer.ovarianCancerMessage}</InputLabel>
				</Grid>

				<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "#4D4D4D",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						Cáncer de colon:
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.clinicalHistory.colonCancer.colonCancerMessage}</InputLabel>
				</Grid>
			</Grid>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Enfermedades ginecológicas
				</Typography>
			</Grid>
			{patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory.uterus === true && (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "black",
							fontWeight: "700",
							marginLeft: "5px",
						}}
					>
						Útero
					</InputLabel>
				</div>
			)}
			{patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory.ovary === true && (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "black",
							fontWeight: "700",
							marginLeft: "5px",
						}}
					>
						Ovario
					</InputLabel>
				</div>
			)}
			{patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory.endometrium === true && (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "black",
							fontWeight: "700",
							marginLeft: "5px",
						}}
					>
						Endometrio
					</InputLabel>
				</div>
			)}
			{patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory.otherCheck === true && (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Avatar src={null} id="avatar-dot-pink"></Avatar>
					<InputLabel
						style={{
							color: "black",
							fontWeight: "700",
							marginLeft: "5px",
						}}
					>
						{patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory.otherInput}
					</InputLabel>
				</div>
			)}
			<Grid item xs={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Antecedentes gineco-obstétricos
				</Typography>
			</Grid>
			<div>
				{/* Título */}
				<Grid container spacing={2} style={{ padding: "16px" }}>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px", marginRight: "5px" }}>Primera menstruación:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>
							{patientItem.clinicalSheetData.gynecologicalHistory.firstMenstruation === "" ? "No" : `${patientItem.clinicalSheetData.gynecologicalHistory.firstMenstruation} años`}
						</InputLabel>
					</Grid>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Fecha de inicio de la última menstruación:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.gynecologicalHistory.lastDate)}</InputLabel>
					</Grid>

					{/* Dejó de menstruar */}
					{patientItem.clinicalSheetData.gynecologicalHistory.lastMenstruation !== 0 && (
						<>
							<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
								<Avatar src={null} id="avatar-dot-pink" />
								<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Dejó de menstruar:</InputLabel>
							</Grid>
							<Grid item xs={8}>
								<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.gynecologicalHistory.lastMenstruation}</InputLabel>
							</Grid>
						</>
					)}

					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Anticonceptivos hormonales:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.gynecologicalHistory.contraceptiveName)}</InputLabel>
					</Grid>

					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Tiempo de uso:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.gynecologicalHistory.usePeriond)}</InputLabel>
					</Grid>

					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Periodo:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.gynecologicalHistory.period}</InputLabel>
					</Grid>
				</Grid>
			</div>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Embarazo
				</Typography>
			</Grid>
			<div>
				<Grid container spacing={2} style={{ padding: "16px" }}>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginRight: "5px", marginLeft: "5px" }}>Inicio de control prenatal:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.pregnancyCase.startdate)}</InputLabel>
					</Grid>
					<Grid item md={12} style={{ marginTop: "10px" }}>
						<Typography variant="subtitle2" gutterBottom>
							Número de partos
						</Typography>
					</Grid>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Normales:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.pregnancyCase.normalBirths}</InputLabel>
					</Grid>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Cesáreas:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.pregnancyCase.caesareanBirths}</InputLabel>
					</Grid>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Abortos:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.pregnancyCase.abortions}</InputLabel>
					</Grid>
					<Grid item md={12} style={{ marginTop: "10px" }}>
						<Typography variant="subtitle2" gutterBottom>
							Lactancia
						</Typography>
					</Grid>
					<Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink" />
						<InputLabel style={{ color: "#4D4D4D", marginLeft: "5px" }}>Fecha de inicio:</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.pregnancyCase.lactationTime)}</InputLabel>
					</Grid>
				</Grid>
			</div>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Antecedentes en senos
				</Typography>
			</Grid>
			<div>
				<Grid container spacing={2} sx={{ padding: "16px" }}>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							¿Se autoexamina los senos?
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{upperCaseFirstLetter(patientItem.clinicalSheetData.breastHistory.selfExamine)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Secreción en:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{patientItem.clinicalSheetData.breastHistory.nipplesSecretions.nipplesSecretionsMessage}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<Avatar src={null} id="avatar-dot-pink"></Avatar>
							<InputLabel
								style={{
									color: "#4D4D4D",
									marginLeft: "5px",
									marginRight: "5px",
								}}
							>
								Color y comentarios de la secreción:
							</InputLabel>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.breastHistory.secretionNotes)}</div>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							¿Tiene prótesis, implantes o moldeantes?
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{upperCaseFirstLetter(patientItem.clinicalSheetData.breastHistory.prosthesis)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de realización del procedimiento:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.breastHistory.prosthesisDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<div style={{ display: "flex", alignItems: "center", marginLeft: "30px" }}>
							<Avatar src={null} id="avatar-dot-pink"></Avatar>
							<InputLabel
								style={{
									color: "#4D4D4D",
									marginLeft: "5px",
									marginRight: "5px",
								}}
							>
								<strong>Comentario:</strong>
							</InputLabel>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.breastHistory.prosthesisNotes)}</div>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							¿Se realizó alguna biopsia o cirugía en los senos?
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{upperCaseFirstLetter(patientItem.clinicalSheetData.breastHistory.biopsy)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de realización del procedimiento:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.breastHistory.biopsyDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<div style={{ display: "flex", alignItems: "center", marginLeft: "30px" }}>
							<Avatar src={null} id="avatar-dot-pink"></Avatar>
							<InputLabel
								style={{
									color: "#4D4D4D",
									marginLeft: "5px",
									marginRight: "5px",
								}}
							>
								<strong>Comentario:</strong>
							</InputLabel>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.breastHistory.biopsyNotes)}</div>
					</Grid>
				</Grid>
			</div>
			<Grid id="grid-style-6" item md={12}>
				<Typography
					variant="subtitle2"
					gutterBottom
					style={{
						color: "#1AA1B9",
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Historial de estudio de senos
				</Typography>
			</Grid>
			<div>
				<Grid container spacing={2} sx={{ paddingInline: "16px" }}>
					<Grid id="grid-style-6" item md={12}>
						<Typography variant="subtitle2" gutterBottom>
							Mastografía rayos x
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de estudio:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.studyBreastHistory.mammogramXDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
							<Avatar src={null} id="avatar-dot-pink"></Avatar>
							<InputLabel
								style={{
									color: "#4D4D4D",
									marginLeft: "5px",
									marginRight: "5px",
								}}
							>
								Diagnóstico:
							</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<div style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.studyBreastHistory.mammogramXDiagnosis)}</div>
					</Grid>
				</Grid>
			</div>
			<div>
				<Grid container spacing={2} sx={{ paddingInline: "16px", paddingBottom: "26px" }}>
					<Grid id="grid-style-6" item md={12}>
						<Typography variant="subtitle2" gutterBottom>
							Ultrasonido
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de estudio:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.studyBreastHistory.ultrasoundDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Diagnóstico:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.studyBreastHistory.ultrasoundDiagnosis)}</InputLabel>
					</Grid>
					<Grid id="grid-style-6" item md={12}>
						<Typography variant="subtitle2" gutterBottom>
							Mastografía M3M
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de estudio:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.studyBreastHistory.mammogramMDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Diagnóstico:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.studyBreastHistory.mammogramMDiagnosis)}</InputLabel>
					</Grid>
					<Grid id="grid-style-6" item md={12}>
						<Typography variant="subtitle2" gutterBottom>
							Resonancia Magnetica
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de estudio:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.studyBreastHistory.mriDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Diagnóstico:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.studyBreastHistory.mriDiagnosis)}</InputLabel>
					</Grid>

					<Grid id="grid-style-6" item md={12}>
						<Typography variant="subtitle2" gutterBottom>
							Otro
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Fecha de estudio:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatDate(patientItem.clinicalSheetData.studyBreastHistory.otherDate)}</InputLabel>
					</Grid>
					<Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
						<Avatar src={null} id="avatar-dot-pink"></Avatar>
						<InputLabel
							style={{
								color: "#4D4D4D",
								marginLeft: "5px",
								marginRight: "5px",
							}}
						>
							Diagnóstico:
						</InputLabel>
					</Grid>
					<Grid item xs={6}>
						{" "}
						<InputLabel style={{ color: "black", fontWeight: "700" }}>{formatEmptyValue(patientItem.clinicalSheetData.studyBreastHistory.otherDiagnosis)}</InputLabel>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default ClinicalSheet;
