/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import {
  Stack,
  Card,
  Grid,
  TextField,
  Divider,
  Tabs,
  Tab,
  Button,
  Modal,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Send, Mail } from "@mui/icons-material";
import theme from "../../assets/theme";
import { sendDiagnoseByEmail } from "../repositories/DiagnosesRepository";

const SendDiagnoseModal = ({ open, patient, handleClose, onSuccess, onErrorSend }) => {
  const [value, setValue] = useState(0);
  const [fieldValue, setFieldValue] = useState(patient?.patientData.email);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [open]);

  useEffect(() => {
    switch (value) {
      case 0:
        setFieldValue(patient?.patientData.email);
        break;
      case 1:
        setFieldValue(patient?.patientData.phone);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, patient]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSend = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("attachments", patient?.diagnoseData?.attachments);
    let responseData = await sendDiagnoseByEmail(patient, fieldValue);

    setLoading(false);

    if (responseData.status === "Ok") {
      onSuccess();
    } else {
      onErrorSend(responseData.message);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          boxShadow: 24,
          px: 2,
          pb: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ mt: "20px" }}>
            <Stack direction="row" spacing="10px">
              <Send color="secondary" />
              <h3>Compartir expediente</h3>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: "10px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              variant="fullWidth"
            >
              <Tab icon={<Mail />} aria-label="phone" />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={8} sx={{ mt: "30px", mb: "20px" }}>
            <InputLabel sx={{ fontSize: "14px", p: "5px" }}>
              {value === 0 ? "Correo electrónico" : "Número telefónico"}
            </InputLabel>
            <TextField
              value={fieldValue}
              onChange={(event) => {
                setFieldValue(event.target.value);
              }}
              sx={{ height: "44px" }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "end",
              mt: "30px",
              mb: "20px",
              px: "30px",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                sx={{
                  height: "44px",
                  m: 0,
                  color: theme.palette.white.one,
                  borderRadius: "10px",
                  textTransform: "none",
                }}
                fullWidth
                onClick={handleSend}
              >
                Enviar
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};

export default SendDiagnoseModal;
