import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Card,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageResultForm from "../forms/ImageResultForm";
import store from "../../store";
import { Provider } from "react-redux";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddNewImageResult = ({
  patientItem,
  openCanvas,
  onlyClose,
  closeSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setOpen(false);
      onlyClose();
    }
  };

  useEffect(() => {
    setOpen(openCanvas);
  }, [openCanvas]);

  const onCloseSuccessForm = (value) => {
    closeSuccess(value);
    handleClose();
  };

  return (
    <Provider store={store}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            backgroundColor: "#EC7BA1",
            borderRadius: "20px 20px 0px 0px",
            position: "fixed",
            height: "100%",
            right: 0,
            bottom: 0,
            marginRight: 0,
            marginBottom: 0,
          },
        }}
      >
        <DialogTitle id="dialog-title-style-1">
          <Grid container flex>
            <Grid item xs={6}>
              <Typography variant="h6" style={{ color: "white" }}>
                Carga de imágenes
              </Typography>
            </Grid>
            <Grid item id="grid-style-8" xs={6}>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent id="dialog-content-style-1">
          <Card id="card-style-3">
            <Box id="box-style-2">
              <ImageResultForm
                patientItem={patientItem}
                onCloseSuccess={onCloseSuccessForm}
              />
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    </Provider>
  );
};

export default AddNewImageResult;
