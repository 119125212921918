import React, { forwardRef, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Card,
  Box,
  Grid,
  Typography,
  Button,
  Chip,
  Stack,
  Badge,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  tableCellClasses,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClinicalSheet from "../components/ClinicalSheet";
import {
  StyledTableRow,
  VisuallyHiddenInput,
} from "../../assets/helpers/tableStyles";
import { createDiagnose } from "../../pages/repositories/DiagnosesRepository";
import { Person } from "@mui/icons-material";
import store from "../../store";
import { Provider } from "react-redux";
import theme from "../../assets/theme";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AddNewDiagnose = ({
  openCanvas,
  onlyClose,
  patientItem,
  closeSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState({});
  const [attachedFilesLength, setAttachedFilesLength] = useState(0);
  const scrollRef = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setOpen(false);
      onlyClose();
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await createDiagnose(patientItem, attachedFiles);
      setLoading(false);

      if (response.status === "Ok" && response.message === "Ok") {
        closeSuccess();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpen(openCanvas);
  }, [openCanvas, patientItem]);

  useEffect(() => {
    setAttachedFilesLength(attachedFiles.length);
  }, [attachedFiles]);

  return (
    <>
      {openCanvas ? (
        <Provider store={store}>
          <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              sx: {
                backgroundColor: "#EC7BA1",
                borderRadius: "20px 20px 0px 0px",
                marginLeft: "55%",
              },
            }}
          >
            <DialogTitle id="dialog-title-style-1">
              <Grid container flex>
                <Grid
                  item
                  xs={8}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Person style={{ marginRight: "6px", color: "white" }} />
                  <Typography variant="h6" style={{ color: "white" }}>
                    {`${patientItem.patientData.name} ${patientItem.patientData.lastname}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  id="grid-style-8"
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Chip
                    variant="filled"
                    label={patientItem.receptionData.studyType}
                    color="secondary"
                    style={{ marginRight: "24px", borderRadius: "20px" }}
                  />
                  <Grid item id="grid-style-8">
                    <IconButton onClick={handleClose} sx={{ color: "white" }}>
                      <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent
              id="div-scrolling-style-result"
              style={{ padding: "0", position: "relative" }}
            >
              <Card id="card-style-5">
                <Stack
                  direction="row"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "26px",
                        fontWeight: "600",
                        marginLeft: "22px",
                        marginTop: "34px",
                        marginBottom: "22px",
                      }}
                    >
                      Registro de diagnóstico
                    </Typography>

                    {patientItem.diagnoseData !== undefined &&
                      patientItem.diagnoseData.attachments?.length > 0 && (
                        <div
                          style={{
                            marginLeft: "22px",
                            cursor: "pointer",
                            color: theme.palette.primary.main,
                          }}
                          onClick={() => {
                            scrollRef.current.scrollIntoView({
                              behavior: "smooth",
                              block: "end",
                              inline: "nearest",
                            });
                          }}
                        >
                          {`Ver ${patientItem.diagnoseData.attachments.length} archivo(s) subidos`}
                        </div>
                      )}
                  </div>

                  <Grid
                    container
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    xs={2}
                    sx={{ pr: 2 }}
                  >
                    <Badge
                      color="error"
                      badgeContent={attachedFilesLength}
                      max={99}
                      invisible={attachedFilesLength === 0}
                    >
                      <Button
                        component="label"
                        variant="contained"
                        fullWidth
                        style={{
                          height: "30%",
                          width: "100%",
                          color: "white",
                          textTransform: "none",
                          fontSize: "14px",
                        }}
                      >
                        Subir archivos
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(event) => {
                            setAttachedFiles(event.target.files);
                          }}
                          multiple
                        />
                      </Button>
                    </Badge>
                  </Grid>
                </Stack>

                <Box id="box-style-2" ref={scrollRef}>
                  <Grid
                    container
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Grid item md={12}>
                      <Card
                        id="div-scrolling-style-result"
                        style={{
                          overflowY: "auto",
                          borderRadius: "10px",
                          borderTopLeftRadius: "0px",
                          borderTopRightRadius: "0px",
                          paddingLeft: "24px",
                          paddingTop: "24px",
                        }}
                      >
                        <ClinicalSheet patientItem={patientItem} />

                        <div
                          style={{ marginBottom: "100px", marginRight: "24px" }}
                        >
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              color: "#1AA1B9",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            Archivos de diagnóstico
                          </Typography>

                          <TableContainer id="table-container-style-1">
                            <Table aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="center">
                                    No.
                                  </StyledTableCell>
                                  <StyledTableCell>Nombre</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {patientItem.diagnoseData !== undefined && (
                                <TableBody>
                                  {patientItem.diagnoseData.attachments.map(
                                    (file) => {
                                      return (
                                        <StyledTableRow key={file.id}>
                                          <StyledTableCell align="center">
                                            {file.id}
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            {file.name}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </DialogContent>

            <div
              style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={attachedFilesLength === 0 || !attachedFilesLength}
                  onClick={handleSave}
                  sx={{
                    height: "48px",
                    width: "204px",
                    backgroundColor: "pink",
                    color: "white",
                    borderRadius: "10px",
                    textTransform: "none",
                    fontSize: "14px",
                  }}
                >
                  Guardar
                </Button>
              )}
            </div>
          </Dialog>
        </Provider>
      ) : null}
    </>
  );
};

export default AddNewDiagnose;
