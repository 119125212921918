/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  Collapse,
  ListItemIcon,
  styled,
} from "@mui/material";
import "../../assets/css/sideBar.css";
import { NavLink } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import theme from "../../assets/theme";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="right" />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.black.one,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.black.one,
  },
}));

const SideBarItem = ({
  item,
  key,
  drawerStatus,
  onClickReturn,
  indexSelected,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List style={{ padding: "0px" }}>
      <CustomTooltip title={drawerStatus ? "" : item.menu}>
        <ListItem
          disablePadding
          key={key}
          sx={{ display: "block", paddingLeft: "4px" }}
          onClick={handleClick}
        >
          <ListItemButton
            style={{ minHeight: 48, justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1,
                  color: theme.palette.white.one,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <text
                style={{
                  display: drawerStatus ? null : "none",
                  color: theme.palette.white.one,
                  fontSize: "14px",
                }}
              >
                {item.menu}
              </text>
            </div>

            <div style={{ display: drawerStatus ? null : "none" }}>
              {open ? (
                <ExpandLess style={{ color: theme.palette.white.one }} />
              ) : (
                <ExpandMore style={{ color: theme.palette.white.one }} />
              )}
            </div>
          </ListItemButton>
        </ListItem>
      </CustomTooltip>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List style={{ padding: "0px" }}>
          {item.subItem.map((itemQ, keyQ) => {
            return (
              <React.Fragment key={key}>
                <CustomTooltip title={drawerStatus ? "" : itemQ.sublabel}>
                  <ListItem
                    disablePadding
                    key={keyQ}
                    sx={{
                      display: "block",
                      backgroundColor:
                        indexSelected === itemQ.id
                          ? theme.palette.white.two
                          : "transparent",
                      paddingLeft: "4px",
                    }}
                    onClick={() => {
                      onClickReturn(itemQ.id);
                    }}
                  >
                    <ListItemButton
                      component={NavLink}
                      to={itemQ.link}
                      sx={{
                        minHeight: 48,
                      }}
                      style={{ marginLeft: drawerStatus ? "24px" : "0px" }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 1,
                          color:
                            indexSelected === itemQ.id
                              ? theme.palette.primary.main
                              : theme.palette.white.one,
                        }}
                      >
                        {itemQ.icon}
                      </ListItemIcon>
                      <text
                        style={{
                          display: drawerStatus ? null : "none",
                          color:
                            indexSelected === itemQ.id
                              ? theme.palette.primary.main
                              : theme.palette.white.one,
                          fontSize: "14px",
                        }}
                      >
                        {itemQ.sublabel}
                      </text>
                    </ListItemButton>
                  </ListItem>
                </CustomTooltip>
              </React.Fragment>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default SideBarItem;
