import { getHeaderSimple, getHeaderMultipart } from "./utils";
import axios from "axios";

export const getDiagnoses = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}diagnose/`, {
		method: "GET",
		headers: getHeaderSimple(),
	});
	const json = await response.json();

	if (json.message === "Ok") {
		return {
			status: "Ok",
			message: "Ok",
			content: json.data,
		};
	} else {
		return {
			status: "Error",
			message: json.content ?? "Error de conexión",
		};
	}
};

export const createDiagnose = async (resultItem, attachments) => {
	const formData = new FormData();

	formData.append("resultId", resultItem?._id);

	// Agregar cada archivo a FormData
	for (let i = 0; i < attachments.length; i++) {
		formData.append("files", attachments[i]); // 'files[]' es el nombre del campo en el backend
	}

	const response = await axios({
		method: "POST",
		url: `${process.env.REACT_APP_API_URL}diagnose`,
		data: formData,
		headers: getHeaderMultipart(),
	});

	const json = response.data;

	if (json.message === "Ok") {
		return {
			status: "Ok",
			message: "Ok",
			content: json.data,
		};
	} else {
		return {
			status: "Error",
			message: json.content ?? "Error de conexión",
		};
	}
};

export const updateDiagnose = async (findings, diagnosticImpression, diagnoseDataId) => {
	const body = {
		findings: findings,
		diagnosticImpression: diagnosticImpression,
	};

	const response = await fetch(`${process.env.REACT_APP_API_URL}diagnose/${diagnoseDataId}`, {
		method: "PUT",
		headers: getHeaderSimple(),
		body: JSON.stringify(body),
	});

	const json = await response.json();

	if (json.message === "Ok") {
		return {
			status: "Ok",
			message: "Ok",
			content: json.data,
		};
	} else {
		return {
			status: "Error",
			message: json.content ?? "Error de conexión",
		};
	}
};

export const sendDiagnoseByWhatsApp = async (resultItem, phone) => {
	const response = await axios({
		method: "POST",
		url: `${process.env.REACT_APP_API_URL}mediasender`,
		headers: getHeaderSimple(),
		data: {
            resultId: resultItem?._id,
            phone: phone,
          },
	});

	const json = response.data;

	if (json.message === "Ok" && json.content === "Ok") {
		return {
			status: "Ok",
			message: "Ok",
		};
	} else {
		return {
			status: "Error",
			message: json.content ?? "Error de conexión",
		};
	}
};

export const sendDiagnoseByEmail = async (resultItem, email) => {
	const response = await axios({
		method: "POST",
		url: `${process.env.REACT_APP_API_URL}mediasender/mail`,
		headers: getHeaderSimple(),
		data: {
            resultId: resultItem?._id,
            email: email,
          },
	});

	const json = response.data;

	if (json.message === "Ok" && json.content === "Ok") {
		return {
			status: "Ok",
			message: "Ok",
		};
	} else {
		return {
			status: "Error",
			message: json.content ?? "Error de conexión",
		};
	}
};
