import Header from "../components/Header";

const headerRoutes = [
  { path: "/", element: null },
  { path: "/dashboard", element: <Header /> },
  { path: "/sheet", element: <Header /> },
  { path: "/reception", element: <Header /> },
  { path: "/results", element: <Header /> },
  { path: "/diagnoses", element: <Header /> },
  { path: "/users", element: <Header /> },
  { path: "/permissions", element: <Header /> },
  { path: "/compare", element: null },
  { path: "/error401", element: <Header /> },
];

export default headerRoutes;
