import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { genderData } from "../../assets/helpers/selectData";
import {
  createGeneralData,
  searchDoctor,
  searchZipCode,
} from "../repositories/ReceptionRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import theme from "../../assets/theme";
import { AddCircleOutline } from "@mui/icons-material";
import dummyMexicoState from "../../assets/helpers/dummyMexicoStates";

const GeneralDataForm = ({ receptionItem, onCloseSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [doctorTextfieldValue, setDoctorTextfieldValue] = useState({
    label: "",
  });
  const [openSearchInput, setOpenSearchInput] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [doctorFound, setDoctorFound] = useState({ label: "found" });
  const [loadingSearchDoctor, setLoadingSearchDoctor] = useState(false);
  const [loadingZipCode, setLoadingZipCode] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      lastname: "",
      birthday: "",
      age: "",
      gender: "",
      phone: "",
      email: "",
      state: "Ciudad de México",
      city: "",
      zipCode: "",
      colony: "",
      street: "",
      exteriorNumber: "",
      interiorNumber: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Campo obligatorio"),
      lastname: Yup.string().required("Campo obligatorio"),
      email: Yup.string()
        .email("Formato incorrecto")
        .required("Campo obligatorio"),
      phone: Yup.string()
        .required("Campo obligatorio")
        .min(10, "10 caracteres")
        .max(10, "10 caracteres"),
      birthday: Yup.string().required("Campo obligatorio"),
      gender: Yup.string().required("Campo obligatorio"),
      city: Yup.string().required("Campo obligatorio"),
      zipCode: Yup.string().required("Campo obligatorio"),
    }),
    onSubmit: async () => {
      setLoading(true);
      const response = await createGeneralData(
        formik.values.name,
        formik.values.lastname,
        formik.values.birthday,
        formik.values.age,
        formik.values.gender,
        formik.values.phone,
        formik.values.email,
        receptionItem.patientData._id,
        receptionItem._id,
        formik.values.state,
        formik.values.city,
        formik.values.zipCode,
        formik.values.colony,
        formik.values.street,
        formik.values.exteriorNumber,
        formik.values.interiorNumber,
        doctorTextfieldValue.data?._id,
        `${doctorTextfieldValue.data?.name} ${doctorTextfieldValue.data?.lastName ?? ""}`
      );
      setLoading(false);

      if (response) {
        if (response.status === "Ok") {
          const generalDataResponse = response.content;
          onCloseSuccess(generalDataResponse);
        } else {
          setSnackSeverity("warning");
          setSnackMessage(response.message);
          setSnackOpen(true);
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues({});
    formik.resetForm();
    setDoctorTextfieldValue({
      label: "",
    });

    if (receptionItem !== null) {
      formik.setValues({
        name: receptionItem.patientData.name,
        lastname: receptionItem.patientData.lastname,
        phone: receptionItem.patientData.phone,
        email: receptionItem.patientData.email,
        birthday: receptionItem.patientData.birthday ?? "",
        age: receptionItem.patientData.age ?? "",
        gender: receptionItem.patientData.gender ?? "",
        state: receptionItem.patientData.state ?? "Ciudad de México",
        city: receptionItem.patientData.city ?? "",
        zipCode: receptionItem.patientData.zipCode ?? "",
        colony: receptionItem.patientData.colony ?? "",
        street: receptionItem.patientData.street ?? "",
        exteriorNumber: receptionItem.patientData.exteriorNumber ?? "",
        interiorNumber: receptionItem.patientData.interiorNumber ?? "",
      });

      if (receptionItem.patientData.doctorId) {
        setDoctorTextfieldValue({
          label: receptionItem.patientData.doctorName,
          data: {
            _id: receptionItem.patientData.doctorId,
            name: receptionItem.patientData.doctorName,
          },
        });
      }
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receptionItem]);

  const calculateAge = (selectedDate) => {
    const today = new Date();
    const birthday = new Date(selectedDate);
    let age = today.getFullYear() - birthday.getFullYear();
    const months = today.getMonth() - birthday.getMonth();

    if (months < 0 || (months === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    formik.setFieldValue("age", age);
    formik.values.age = age;
  };

  const delayedHandleSearch = debounce(async (search) => {
    if (search !== "") {
      setLoadingSearchDoctor(true);
      const response = await searchDoctor(search);
      const formatList = [];

      for (let q = 0; q < response.content.length; q += 1) {
        const itemQ = response.content[q];

        formatList.push({
          label: `${itemQ.name} ${itemQ.lastName}`,
          data: itemQ,
        });
      }

      setLoadingSearchDoctor(false);
      setDoctorList(formatList);
      setDoctorTextfieldValue({ label: search });
    } else {
      setLoadingSearchDoctor(false);
      setDoctorList([]);
    }
  }, 800);

  const onSelectDoctor = (value) => {
    setDoctorFound(value);
    setDoctorTextfieldValue(value);
  };

  const addNewDoctor = (value) => {
    const doctorItem = {
      label: value.label,
      data: {
        name: value.label,
      },
    };
    setDoctorFound(doctorItem);
    setDoctorTextfieldValue(doctorItem);
  };

  const handlerZipCode = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      formik.setFieldValue("zipCode", e.target.value);
      delayedHandleSearchByZipCode(e.target.value);
    } else {
      formik.setFieldValue("zipCode", "");
      formik.setFieldValue("city", "");
      setSnackSeverity("warning");
      setSnackMessage("Solo números");
      setSnackOpen(true);
    }
  };

  const delayedHandleSearchByZipCode = debounce(async (search) => {
    setLoadingZipCode(true);
    if (search !== "" && search.length === 5) {
      const response = await searchZipCode(search);

      if (response.status === "Ok" && response.content.length > 0) {
        formik.setFieldValue("city", response.content[0].municipality);
      } else {
        formik.setFieldValue("city", "");
        setSnackSeverity("warning");
        setSnackMessage("No hay resultados");
        setSnackOpen(true);
      }
    } else if (search.length > 5) {
      formik.setFieldValue("city", "");
      setSnackSeverity("warning");
      setSnackMessage("El código postal debe tener 5 dígitos");
      setSnackOpen(true);
    } else {
      formik.setFieldValue("city", "");
    }

    setLoadingZipCode(false);
  }, 800);

  return (
    <div
      style={{
        marginLeft: "30px",
        marginRight: "30px",
        marginTop: "27px",
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        Datos personales
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} style={{ marginTop: "15px" }}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <InputLabel shrink>Nombre</InputLabel>
            <TextField
              id="name"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
                formik.values.name = e.target.value;
              }}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.name && formik.touched.name) && (
              <div id="div-style-input-error">{formik.errors.name}</div>
            )}
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <InputLabel shrink>Apellido</InputLabel>
            <TextField
              id="lastname"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("lastname", e.target.value);
                formik.values.lastname = e.target.value;
              }}
              value={formik.values.lastname}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.lastname && formik.touched.lastname) && (
              <div id="div-style-input-error">{formik.errors.lastname}</div>
            )}
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={9}>
            <InputLabel shrink>Fecha de nacimiento</InputLabel>
            <TextField
              id="birthday"
              size="small"
              type="date"
              onChange={(e) => {
                formik.setFieldValue("birthday", e.target.value);
                formik.values.birthday = e.target.value;
                calculateAge(e.target.value);
              }}
              value={formik.values.birthday}
              onBlur={formik.handleBlur}
              maxDate={new Date(2023, 12, 31)}
            />
            {Boolean(formik.errors.birthday && formik.touched.birthday) && (
              <div id="div-style-input-error">{formik.errors.birthday}</div>
            )}
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={6} xs={3}>
            <InputLabel shrink>Edad</InputLabel>
            <TextField
              id="age"
              size="small"
              disabled
              value={formik.values.age}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
            <InputLabel shrink>Género</InputLabel>
            <TextField
              id="gender"
              size="small"
              select
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("gender", e.target.value);
                formik.values.gender = e.target.value;
              }}
            >
              {genderData.map((genders) => (
                <MenuItem key={genders.id} value={genders.gender}>
                  {genders.gender}
                </MenuItem>
              ))}
            </TextField>
            {Boolean(formik.errors.gender && formik.touched.gender) && (
              <div id="div-style-input-error">{formik.errors.gender}</div>
            )}
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
            <InputLabel shrink>Teléfono</InputLabel>
            <TextField
              id="phone"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("phone", e.target.value);
                formik.values.phone = e.target.value;
              }}
              value={formik.values.phone}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.phone && formik.touched.phone) && (
              <div id="div-style-input-error">{formik.errors.phone}</div>
            )}
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <InputLabel shrink>Correo electrónico</InputLabel>
            <TextField
              id="email"
              size="small"
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                formik.values.email = e.target.value;
              }}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.email && formik.touched.email) && (
              <div id="div-style-input-error">{formik.errors.email}</div>
            )}
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Typography variant="subtitle2" gutterBottom>
              Dirección
            </Typography>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <InputLabel shrink>Estado</InputLabel>
            <TextField
              select
              id="state"
              size="small"
              value={formik.values.state}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("state", e.target.value);
                formik.values.state = e.target.value;
              }}
            >
              {dummyMexicoState.map((state) => (
                <MenuItem key={state.id} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
            {Boolean(formik.errors.state && formik.touched.state) && (
              <div id="div-style-input-error">{formik.errors.state}</div>
            )}
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <InputLabel shrink>Código postal</InputLabel>
            <TextField
              id="zipCode"
              size="small"
              value={formik.values.zipCode}
              onChange={(event) => {
                // formik.setFieldValue("zipCode", event.target.value);
                handlerZipCode(event);
                // delayedHandleSearchByZipCode(event.target.value);
              }}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {loadingZipCode ? (
                      <CircularProgress
                        size={16}
                        style={{ color: theme.palette.secondary.main }}
                      />
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
            {Boolean(formik.errors.zipCode && formik.touched.zipCode) && (
              <div id="div-style-input-error">{formik.errors.zipCode}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Ciudad / delegación / municipio</InputLabel>
            <TextField
              id="city"
              size="small"
              value={formik.values.city}
              autoComplete="off"
              disabled
            />
            {Boolean(formik.errors.city && formik.touched.city) && (
              <div id="div-style-input-error">{formik.errors.city}</div>
            )}
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <InputLabel shrink>Colonia</InputLabel>
            <TextField
              id="colony"
              size="small"
              value={formik.values.colony}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <InputLabel shrink>Calle</InputLabel>
            <TextField
              id="street"
              size="small"
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
            <InputLabel shrink>Número exterior</InputLabel>
            <TextField
              id="exteriorNumber"
              size="small"
              value={formik.values.exteriorNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
            <InputLabel shrink>Número interior</InputLabel>
            <TextField
              id="interiorNumber"
              size="small"
              value={formik.values.interiorNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Typography variant="subtitle2" gutterBottom>
              Médico de cabecera
            </Typography>
          </Grid>

          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={11}
            xs={11}
            style={{ marginTop: "8px" }}
          >
            <InputLabel shrink>Buscar doctor</InputLabel>
            <Autocomplete
              disablePortal
              size="small"
              fullWidth
              value={doctorTextfieldValue}
              id="free-solo-2-demo"
              noOptionsText={"Sin resultados"}
              open={openSearchInput}
              onOpen={() => {
                setOpenSearchInput(true);
              }}
              onClose={() => {
                setOpenSearchInput(false);
              }}
              options={doctorList}
              inputProps={{ style: { fontSize: 14 } }}
              onChange={(event, value) => {
                if (value !== null) {
                  if (openSearchInput) {
                    if (value) onSelectDoctor(value);
                  }
                } else {
                  setDoctorFound({ label: "found" });
                  setDoctorTextfieldValue({ label: "" });
                }
              }}
              onSelect={(event) => {
                if (
                  openSearchInput &&
                  doctorFound.label !== doctorTextfieldValue.label
                ) {
                  delayedHandleSearch(event.target.value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingSearchDoctor ? (
                          <CircularProgress
                            size={16}
                            style={{ color: theme.palette.secondary.main }}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ marginTop: "28px" }}
          >
            <IconButton
              onClick={() => {
                addNewDoctor(doctorTextfieldValue);
              }}
            >
              <AddCircleOutline />
            </IconButton>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{ marginTop: "8px" }}
          >
            <InputLabel shrink>Doctor</InputLabel>
            <TextField
              size="small"
              fullWidth
              autoComplete="off"
              value={doctorTextfieldValue.data?.name ? `${doctorTextfieldValue.data.name} ${doctorTextfieldValue.data?.lastName ?? ""}` : ""}
              inputProps={{ style: { fontSize: 14 } }}
              disabled
            />
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: "100px",
            marginBottom: "24px",
            textAlign: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                paddingLeft: "30%",
                paddingRight: "30%",
              }}
            >
              <Button
                id="button-style-save-reception-form"
                sise="small"
                type="submit"
                variant="contained"
                fullWidth
              >
                Guardar
              </Button>
            </div>
          )}
        </div>

        <SnackBarMessage
          severity={snackSeverity}
          message={snackMessage}
          open={snackOpen}
          onClose={() => {
            setSnackOpen(false);
          }}
        />
      </form>
    </div>
  );
};

export default GeneralDataForm;
