import { styled } from "@mui/material/styles";
import { TableCell, TableRow} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "lightgray",
		color: "black",
		fontSize: 15,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 15,
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

export const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});