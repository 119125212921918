/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Paper,
  IconButton,
  CardContent,
  CardActions,
  Tooltip,
  Fade,
  Button,
  TablePagination,
  InputBase,
} from "@mui/material";
import { Search, Folder, FileDownload, Send } from "@mui/icons-material";
import AddNewDiagnose from "../offcanvas/AddNewDiagnose";
import { getDiagnoses } from "../repositories/DiagnosesRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import { useNavigate } from "react-router-dom";
import SendDiagnoseModal from "../modal/SendDiagnoseModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  " &:first-child tr, &:first-child": {
    borderTopLeftRadius: "50px",
  },
  " &:first-child tr, &:last-child": {
    borderTopRightRadius: "50px",
  },
  " &:last-child tr, &:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

const CommonDiagnosesEvents = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openPatientCanvas, setOpenPatientCanvas] = useState(false);
  const [patientSelected, setPatientSelected] = useState(null);
  const [diagnosesList, setDiagnosesList] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [openSendCanvas, setOpenSendCanvas] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - diagnosesList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpenPatientCanvas(true);
  };

  const onlyClose = () => {
    setOpenPatientCanvas(false);
  };

  const handleSend = () => {
    setOpenSendCanvas(true);
  };

  const handleCloseSend = () => {
    setOpenSendCanvas(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const response = await getDiagnoses();
    if (response) {
      if (response.status === "Ok") {
        setDiagnosesList(response.content);
      } else if (response.status === "401") {
        setDiagnosesList([]);
        navigate(`../error401`);
      } else {
        setDiagnosesList([]);
      }
    }
  };

  const onCloseSuccess = (value) => {
    setOpenPatientCanvas(false);
    setSnackSeverity("success");
    setSnackMessage("Se guardó correctamente");
    setSnackOpen(true);
    getData();
    setOpenSendCanvas(true);
  };

  const onSuccessSendDiagnose = () => {
    setOpenSendCanvas(false);
    setSnackSeverity("success");
    setSnackMessage("Se envió correctamente");
    setSnackOpen(true);
  };

  const onErrorSendDiagnose = (errorMessage) => {
    setOpenSendCanvas(false);
    setSnackSeverity("warning");
    setSnackMessage(errorMessage);
    setSnackOpen(true);
  };

  return (
    <div>
      <div id="div-style-8">
        <Folder color={"primary"} fontSize="large" id="icon-style-1" />
        <h2>Diagnósticos</h2>
      </div>
      <Card id="card-style-2">
        <CardContent>
          <Grid container spacing={2} id="grid-style-2">
            <Grid xl={6} lg={8} md={10} sm={12} xs={12}>
              <Paper component="form" id="paper-style-2">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar..."
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton type="button" id="icon-style-2" aria-label="search">
                  <Search color="primary" />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xl={6} lg={4} md={2} sm={12} xs={12} id="grid-style-3">
              <Button
                component="label"
                size="small"
                variant="outlined"
                id="button-style-3"
              >
                <FileDownload /> Descargar
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <TableContainer id="table-container-style-1">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">No.</StyledTableCell>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell align="center">Edad</StyledTableCell>
                    <StyledTableCell>Género</StyledTableCell>
                    <StyledTableCell>Teléfono</StyledTableCell>
                    <StyledTableCell>Correo</StyledTableCell>
                    <StyledTableCell>Motivo</StyledTableCell>
                    <StyledTableCell align="center">Estado</StyledTableCell>
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {diagnosesList &&
                    diagnosesList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">
                              {row.id}
                            </StyledTableCell>
                            <StyledTableCell>{`${row.patientData?.name} ${row.patientData?.lastname}`}</StyledTableCell>
                            <StyledTableCell align="center">
                              {row.patientData?.age}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.patientData?.gender}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.patientData?.phone}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.patientData?.email}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.receptionData?.reason}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row?.status}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip
                                title="Agregar diagnóstico"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    setPatientSelected(row);
                                    setUpdateKey(updateKey + 1);
                                    handleClickOpen();
                                  }}
                                >
                                  <Folder fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Enviar diagnóstico"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  color="secondary"
                                  onClick={() => {
                                    setPatientSelected(row);
                                    setUpdateKey(updateKey + 1);
                                    handleSend();
                                  }}
                                >
                                  <Send fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
        <CardActions>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={diagnosesList.length}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Resultados por página:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        </CardActions>
      </Card>
      <AddNewDiagnose
        openCanvas={openPatientCanvas}
        onlyClose={onlyClose}
        patientItem={patientSelected}
        closeSuccess={onCloseSuccess}
      />
      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
      <SendDiagnoseModal
        open={openSendCanvas}
        patient={patientSelected}
        handleClose={handleCloseSend}
        onSuccess={onSuccessSendDiagnose}
        onErrorSend={onErrorSendDiagnose}
      />
    </div>
  );
};

export default CommonDiagnosesEvents;
